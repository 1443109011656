a {
  color: #008791;
  text-decoration: none;
}
a:hover {
  color: #008791 !important;
  text-decoration: underline;
}

a:focus {
  color: #008791;
  outline: none !important;
}
 
