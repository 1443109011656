/**
 * @file
 * Synapse - adaptive theme */
@import "variables/_index";

@import "typography/_link";
@import "typography/_table";
@import "typography/_img";
@import "typography/_blockquote";
@import "typography/_elements";
@import "typography/_button";
@import "typography/_modals";


@font-face {
  font-family: 'pf_din_text_cond_proregular';
  src: url('../fonts/pfdintextcondpro-medium/pfdintextcondpro-medium-webfont.woff2') format('woff2'),
    url('../fonts/pfdintextcondpro-medium/pfdintextcondpro-medium-webfont.woff') format('woff'),
    url('../fonts/pfdintextcondpro-medium/pfdintextcondpro-medium-webfont.ttf') format('truetype'),
    url('../fonts/pfdintextcondpro-medium/pfdintextcondpro-medium-webfont.svg#pf_din_text_cond_promedium') format('svg');
  font-weight: bold;
  font-style: normal;

}


@font-face {
  font-family: 'pf_din_text_cond_proregular';
  src: url('../fonts/pfdintextcondpro-regular/pfdintextcondpro-regular-webfont.woff2') format('woff2'),
    url('../fonts/pfdintextcondpro-regular/pfdintextcondpro-regular-webfont.woff') format('woff'),
    url('../fonts/pfdintextcondpro-regular/pfdintextcondpro-regular-webfont.ttf') format('truetype'),
    url('../fonts/pfdintextcondpro-regular/pfdintextcondpro-regular-webfont.svg#pf_din_text_cond_proregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'PFDinTextCondPro';
  src: url('../fonts/pfdintextcondpro-bold/PFDinTextPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/pfdintextcondpro-bold/PFDinTextPro-Bold.otf') format('opentype'),
    url('../fonts/pfdintextcondpro-bold/PFDinTextPro-Bold.woff') format('woff'),
    url('../fonts/pfdintextcondpro-bold/PFDinTextPro-Bold.ttf') format('truetype'),
    url('../fonts/pfdintextcondpro-bold/PFDinTextPro-Bold.svg#PFDinTextPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/pfdintextcondpro-regular2/PFDinTextPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/pfdintextcondpro-regular2/PFDinTextPro-Regular.otf') format('opentype'),
    url('../fonts/pfdintextcondpro-regular2/PFDinTextPro-Regular.woff') format('woff'), url('../fonts/pfdintextcondpro-regular2/PFDinTextPro-Regular.ttf') format('truetype'),
    url('../fonts/pfdintextcondpro-regular2/PFDinTextPro-Regular.svg#PFDinTextPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/**
 * Main Styles
 */
html {
  font-size: $font-size-base;
}

body {
  color: #333333;
  background-color: $body-bg;
  font-family: 'pf_din_text_cond_proregular', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  min-width: 375px;
}

/** Headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'pf_din_text_cond_proregular';
  font-weight: bold;
  margin-bottom: .5rem;
  line-height: 1.1em;
}

h1 {
  font-size: 38px;
  margin-bottom: 30px;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

a {
  text-decoration: none !important;
}

.breadcrumb {
  padding: 10px 0;
  background: none;
  border-radius: 0;
  font-size: 18px;
  line-height: 23px;
  font-family: 'pf_din_text_cond_proregular';
  padding: 0;
  margin: 30px 0 15px 0;

  a {
    color: #008791;
    border-bottom: 1px solid;
    &:hover {
      text-decoration: none;
      border-color: transparent;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }

  & li:before {
    content: ' > ';
  }
}

.text-formatted {
  li {
    list-style: none;
    position: relative;
    padding: 0 0 15px 0;

    &:before {
      content: "";
      width: 14px;
      height: 2px;
      background: #000;
      position: absolute;
      left: -24px;
      top: 10px;
    }
  }
}


.btn {
  border: 0;
  background: #008791;
  color: #fff;
  font-family: 'pf_din_text_cond_proregular';
  font-weight: bold;
  font-size: 20px;
  padding: 13px 40px;
  border-radius: 5px;

  &:hover,
  &:focus {
    box-shadow: none;
    background: #0d9ea9;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }
}

/** modal **/
.modal-dialog {
  max-width: 470px;
}

.modal-content {
  background: url(../images/gray-bg.png) repeat 0 0;
  border: 0;
  border-radius: 0;
  // box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);

  h4 {
    text-align: center;
    color: #008791;
    font-size: 36px;
    text-align: center;
    width: 100%;
  }

  form label {
    display: none;
  }

  .field--name-field-form-order input {
    padding: 0 !important;
  }

  .field--name-field-form-order input,
  .field--name-field-form-order input:hover,
  .field--name-field-form-order input:focus {
    border: 0 !important;
    text-align: center !important;
    background: none !important;
    border-radius: 0 !important;
    cursor: auto;
    color: #333333 !important;
  }

  .modal-header {
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
  }

  .close {
    background: url(../images/close-icons.png) no-repeat 0 0;
    width: 40px;
    height: 40px;
    opacity: 1;
    right: 0;
    text-indent: -9999px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
      opacity: 0.7;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }

  form {
    input.form-control {
      color: #008791;
      border-color: #dddddd;
      padding: 13px 15px;

      &:focus {
        border-color: #008791;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }

    textarea {
      padding: 13px 15px;
      border-color: #dddddd;
      height: 175px;
      resize: none;

      &:focus {
        border-color: #008791;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }

    ::-webkit-input-placeholder {
      color: #008791;
    }

    ::-moz-placeholder {
      color: #008791;
    }

    :-moz-placeholder {
      color: #008791;
    }

    :-ms-input-placeholder {
      color: #008791;
    }
  }
}
