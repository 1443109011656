/** Tables */
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

table th,
table td {
  padding: .75rem;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid #017e86;
}
table tbody tr:nth-of-type(odd) {
  background: none;
}
table thead th {
  vertical-align: bottom;
  border: 1px solid #017e86;
}

table tbody + tbody {
  border-top: 1px solid #017e86;
}

table table {
  background: none;
}
